<template>
  <div class="main-bindMobile">
      <div class="views">
          <div class="title">账号绑定</div>
          <div class="bd-title">微信授权成功，请输入账号完成绑定</div>
          <div class="zh-title">手机号</div>
          <input class="input-title" type="text" v-model="account" placeholder="请输入手机号">
          <div class="mm-title">验证码</div>
          <div class="code-view">
              <input class="input-title" type="text" v-model="pwd" placeholder="请输入验证码">
              <div class="code-title" :style="{color:isSkin}" @click.stop="sendMethod()">{{codeBtn}}</div>
          </div>
          <div class="login" :style="{backgroundColor:isSkin}" @click.stop="loginMethod()">绑定</div>
          <div class="wj-title" @click.stop="forgetPwdMethod()">注册账号</div>
      </div>
  </div>
</template>

<script>
import {Message} from 'element-ui'
export default {
    data() {
        return {
            account:'',
            pwd:'',
            codeBtn: '获取验证码',
            isClick: true,
            openID:'',
            type:''
        }
    },
    created(){
        this.openID = this.$route.query.openID
        this.type = this.$route.query.type
        this.$store.commit('changClick',1)
        var skin = sessionStorage.getItem('Skin')
        var zskin = sessionStorage.getItem('ZSkin')
        if (skin) {
            this.$store.commit('changSkin',skin)
        }
        if (zskin) {
            this.$store.commit('changZSkin',zskin)
        }
    },
    computed:{
        isSkin(){
            return this.$store.state.isSkin
        }
    },
    methods:{
        /**注册账号**/ 
        forgetPwdMethod(){
            this.$router.push('/register');
            this.$store.commit('changClick',1)
        },
        /**绑定微信登陆**/ 
        loginMethod(){
            var that = this
            if (that.account == '') {
                Message({
                    showClose: true,
                    message: '请输入手机号！',
                    type: 'error'
                })
                return
			}
            if (that.code == '') {
                Message({
                    showClose: true,
                    message: '请输入验证码！',
                    type: 'error'
                })
                return
			}
            that.$api.getWXLogin({
                code:that.pwd,
                openId:that.openID,
                mobile:that.account
            }).then(res=>{
                if (res.data.code == 0) {
                    sessionStorage.setItem('token',res.data.data.token)
                    sessionStorage.setItem('userHeadImg',res.data.data.userInfo.headImgUrl)
                    sessionStorage.setItem('userName',res.data.data.userInfo.userName)
                    sessionStorage.setItem('userNickName',res.data.data.userInfo.nickname)
                    sessionStorage.setItem('Teachers',res.data.data.userInfo.identity)
                    sessionStorage.setItem('bindWechat',res.data.data.userInfo.bindWechat)
                    if (that.type == 1) {
                        Message({
                            showClose: true,
                            message: '微信绑定成功！',
                            type: 'success'
                        })
                        setTimeout(()=>{
                            this.$router.push('/mySetting');
                            this.$store.commit('changClick',1)
                            this.$store.commit('changLeft',5)
                            this.$store.commit('changNickname',res.data.data.userInfo.nickname)
                            this.$store.commit('changHeadImgUrl',res.data.data.userInfo.headImgUrl)
                            this.$store.commit('changTeachers',res.data.data.userInfo.identity)
                        },2000)
                    }else{
                        Message({
                            showClose: true,
                            message: '登录成功！',
                            type: 'success'
                        })
                        setTimeout(()=>{
                            this.$router.push('/');
                            this.$store.commit('changClick',0)
                            this.$store.commit('changLogin',1)
                            this.$store.commit('changNickname',res.data.data.userInfo.nickname)
                            this.$store.commit('changHeadImgUrl',res.data.data.userInfo.headImgUrl)
                            this.$store.commit('changTeachers',res.data.data.userInfo.identity)
                        },2000)
                    }
                }
            })
        },
        /**获取验证码**/ 
        sendMethod(){
            var that = this;
			let count = 60;
			if (that.account == '') {
                Message({
                showClose: true,
                message: '请输入手机号！',
                type: 'error'
                })
			}else if (that.isClick) {
                that.$api.getSmsCode({
                    mobile:that.account,
                }).then(res=>{
                    console.log(res);
                    if (res.data.code == 0) {
                        Message({
                            showClose: true,
                            message: '已发送，请注意查收',
                            type: 'success'
                        })
                        that.isClick = false;
                        let timer = setInterval(function() {
                            count--;
                            that.codeBtn = '获取中(' + count + ')';
                            if (count == 0) {
                                that.isClick = true;
                                clearInterval(timer)
                                that.codeBtn = '重新获取';
                            }
                        }, 1000)
                    }else{
                        that.isClick = true;
                    }
                })
            }
        }
    }
}
</script>

<style scoped lang="scss">
.main-bindMobile{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .views{
        width: 427px;
        height: 396px;
        background: #FCFCFC;
        margin-top: 50px;
        margin-bottom: 175px;
        .title{
            font-size: 24px;
            line-height: 34px;
            color: #333333;
            margin-left: 20px;
            margin-top: 30px;
        }
        .bd-title{
            margin-left: 20px;
            margin-top: 10px;
            font-size: 14px;
            line-height: 17px;
            color: #333333;
        }
        .zh-title{
            font-size: 14px;
            line-height: 20px;
            color: #333333;
            margin-left: 20px;
            margin-top: 40px;
        }
        .mm-title{
            font-size: 14px;
            line-height: 20px;
            color: #333333;
            margin-left: 20px;
            margin-top: 20px;
        }
        .code-view{
            position: relative;
            display: flex;
            justify-items: center;
            align-items: center;
            .code-title{
                font-size: 14px;
                line-height: 20px;
                position: absolute;
                right: 30px;
                margin-top: 10px;
                cursor: pointer;
            }
        }
        .login{
            width: 387px;
            height: 42px;
            margin-left: 20px;
            margin-top: 40px;
            border-radius: 4px;
            font-size: 16px;
            line-height: 42px;
            text-align: center;
            color: #FFFFFF;
            cursor: pointer;
        }
        .wj-title{
            width: 60px;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            color: #666;
            cursor: pointer;
            margin-left: 347px;
            margin-top: 20px;
        }
    }
}
.input-title{
    width: 387px;
    height: 40px;
    background: #FCFCFC;
    border: 1px solid #DDDDDD;
    box-sizing: border-box;
    border-radius: 4px;
    outline:none; //去点击蓝色边框
    padding-left: 10px;
    padding-right: 100px;
    font-size: 15px;
    line-height: 40px;
    color: #666666;
    margin-left: 20px;
    margin-top: 10px;
}
</style>